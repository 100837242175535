<div class="container-status">
  <ul class="ul-left-margin">
    <!-- Status -->
    <li>
      <div class="status-and-info-toggle">
        <div>
          <span class="item-key">Status: </span>
          <span class="item-value">{{
            (getActiveContainer$(containerDef) | async)?.status ?? "Offline"
          }}</span>
        </div>
        <button mat-flat-button (click)="toggleExtraContainerStatus()">
          {{ getExtraContainerToggleText() }}
        </button>
      </div>
    </li>

    <!-- Command -->
    <li *ngIf="showExtraInfo" class="show-extra-info">
      <div class="flex-row">
        <div class="item-key">Entrypoint Command :</div>
        <div class="item-value mono">
          {{ (getActiveContainer$(containerDef) | async)?.command }}
        </div>
      </div>
    </li>

    <!-- Name -->
    <li *ngIf="showExtraInfo" class="show-extra-info">
      <div class="flex-row">
        <div class="item-key">Container Name:</div>
        <div class="item-value mono">
          <div
            *ngFor="
              let name of (getActiveContainer$(containerDef) | async)?.names ??
                []
            "
          >
            {{ name }}
          </div>
        </div>
      </div>
    </li>

    <!-- networks -->
    <li *ngIf="showExtraInfo" class="show-extra-info">
      <div class="flex-row">
        <div class="item-key">Networks:</div>
        <div class="item-value mono">
          <div
            *ngFor="
              let name of (getActiveContainer$(containerDef) | async)
                ?.networks ?? []
            "
          >
            {{ name }}
          </div>
        </div>
      </div>
    </li>

    <!-- Ports -->
    <li *ngIf="showExtraExtraInfo" class="show-extra-extra-info">
      <div class="flex-row">
        <div class="item-key">Ports:</div>
        <div class="item-value mono">
          <div
            *ngFor="
              let port of (getActiveContainer$(containerDef) | async)?.ports
            "
          >
            {{ port }}
          </div>
        </div>
      </div>
    </li>

    <!-- Mounts -->
    <li *ngIf="showExtraExtraInfo" class="show-extra-extra-info">
      <div class="flex-row">
        <div class="item-key">Mounts:</div>
        <div class="item-value mono">
          <div
            *ngFor="
              let mount of (getActiveContainer$(containerDef) | async)?.mounts
            "
          >
            {{ mount }}
          </div>
        </div>
      </div>
    </li>
  </ul>
</div>
