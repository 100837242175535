
<div mat-dialog-title> Create New Environment </div>
<mat-dialog-content>
  <mat-card appearance="outlined">
    <mat-card-content>
      <p>
        This will create a new environment which will spin up a new server on the
        specified proxy port.
      </p>

      <mat-form [formGroup]="form" class="new-env-configuration">
        <mat-form-field>
          <mat-label>Environment Alias</mat-label>
          <mat-hint
            >Assign an alias to the environment so it's easier to know what it's
            for.</mat-hint
          >
          <input
            matInput
            placeholder="Enter Alias..."
            formControlName="envAlias"
            id="envAlias"
          />
          <mat-error *ngIf="envAlias.invalid && envAlias.value.length > 0">{{
            getErrorMessage(envAlias)
          }}</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Minecraft Proxy Port</mat-label>
          <!-- TODO: The port values should be supplied by the env dialog, which should be getting it from the yc-api instead. -->
          <mat-hint
            >The port the proxy will run on. This will be the port you connect to
            in Minecraft. Port must be between 25600-25700 and not already used by
            another environment.</mat-hint
          >
          <input
            matInput
            placeholder="Enter Port..."
            formControlName="proxyPort"
            id="proxyPort"
          />
          <mat-error *ngIf="proxyPort.invalid && proxyPort.value.length > 0">{{
            getErrorMessage(proxyPort)
          }}</mat-error>
        </mat-form-field>

        <mat-form-field class="extra-spacer">
          <mat-label
            >Environment Description
            <span class="minitext">(Optional)</span></mat-label
          >
          <mat-hint
            >Add a description on what the environment is intended for.</mat-hint
          >
          <textarea
            matInput
            placeholder="Enter Description..."
            formControlName="description"
            id="description"
          ></textarea>
          <mat-error
            *ngIf="description.invalid && description.value.length > 0"
            >{{ getErrorMessage(description) }}</mat-error
          >
        </mat-form-field>

        <mat-form-field class="extra-spacer">
          <mat-label>Server Type</mat-label>
          <mat-select
            formControlName="serverType"
            id="serverType"
            required
          >
            <mat-option *ngFor="let type of serverTypes" [value]="type">
              {{type.name}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="serverType.hasError('required')">Please choose a server type!</mat-error>
          <mat-hint>{{serverType.value?.hint}}</mat-hint>
        </mat-form-field>

        <div class="extra-spacer">
          <mat-checkbox formControlName="enableEnvProtection"
            >Enable Env Protection</mat-checkbox
          >
          <div class="checkbox-hint">
            "Env Protection" will prevent deleting the environment. To disable
            protection, manually modify the config flag
            <span class="mono"
              >general.enable_environment_protection = false</span
            >
          </div>
        </div>
      </mat-form>

    </mat-card-content>
  </mat-card>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button (click)="createNewEnv()" color="primary">
    Create New Environment
  </button>
</mat-dialog-actions>
