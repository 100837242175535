<mat-toolbar>
  <span>Environment Management</span>
  <button
    mat-icon-button
    (click)="openNewEnvDialog()"
    matTooltip="Create New Environment"
  >
    <mat-icon> add_box </mat-icon>
  </button>
</mat-toolbar>

<mat-tab-group
  (selectedIndexChange)="selectedIndexChanged($event)"
  [selectedIndex]="activeTabIndex$ | async"
  [disablePagination]="true"
>
  <mat-tab
    *ngFor="let env of availableEnvs$ | async"
    [label]="env.getFormattedLabel()"
  >
    <div class="container-tab">
      <div class="container-summary">
        <h3>Overview</h3>
        <mat-card appearance="outlined">
          <mat-card-title> Summary </mat-card-title>
          <mat-card-content>
            <div class="env-info">
              <div style="font-size: 0.8em">
                <ul class="ul-left-margin">
                  <li>
                    <span class="item-key">Running on Port: </span
                    ><span class="item-val">{{ env.config.clusterVariables["velocityPort"] }}</span>
                  </li>
                  <li>
                    <span class="item-key">Server Version: </span
                    ><span class="item-val">{{ env.config.clusterVariables["mcVersion"] }}</span>
                  </li>
                  <li>
                    <span class="item-key">Server Type: </span
                    ><span class="item-val">{{ env.config.clusterVariables["mcType"] }}</span>
                  </li>
                  <li>
                    <span class="item-key">Server Build: </span
                    ><span class="item-val">{{ env.config.clusterVariables["paperBuild"] }}</span>
                    <!-- TODO: Make this read from actual jar? Version between paper/fabric/etc will not necessarily match env vars. -->
                    <!-- TODO: Do I care that PASCAL_CASE env vars are being camelCase'd--->
                  </li>
                </ul>
              </div>
              <div
                style="margin-top: 8px"
                [innerHtml]="env.getRenderedDescription()"
              ></div>
            </div>

            <div class="env-actions">
              <div>
                <button
                  mat-mini-fab
                  matTooltip="Start Environment"
                  (click)="startEnvironment()"
                  [disabled]="startEnvironmentDisabled()"
                >
                  <mat-icon> play_circle_filled </mat-icon>
                </button>
                <button
                  mat-mini-fab
                  matTooltip="Shut Down Environment"
                  (click)="stopEnvironment()"
                  [disabled]="stopEnvironmentDisabled()"
                >
                  <mat-icon> stop </mat-icon>
                </button>
                <button
                  mat-mini-fab
                  matTooltip="Attempt to Restart Environment"
                  (click)="restartEnvironment()"
                  [disabled]="restartEnvironmentDisabled()"
                >
                  <mat-icon> replay </mat-icon>
                </button>
                <button
                  mat-mini-fab
                  (click)="editEnvironment()"
                  matTooltip="Edit Environment Config"
                >
                  <mat-icon> edit </mat-icon>
                </button>
                <button
                  *ngIf="!(env.name === 'env1') && isDeleteEnvironmentDisabled"
                  mat-mini-fab
                  (click)="deleteEnvironment()"
                  matTooltip="DELETE ENVIRONMENT"
                >
                  <mat-icon svgIcon="trash" class="filter-to-white"></mat-icon>
                </button>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="containers">
        <div class="aux-containers">
          <!-- TODO: This is getting ugly/repetitive. Use templates/looping -->
          <h3>Aux Containers</h3>
          <!-- Velocity -->
          <div
            *ngFor="
              let container of getDefinedContainersForEnvAndType$(
                env,
                ContainerType.MCProxy
              ) | async
            "
          >
            <app-container-status-card
              [env]="env"
              [containerDef]="container"
            ></app-container-status-card>
          </div>
          <!-- MySQL -->
          <div
            *ngFor="
              let container of getDefinedContainersForEnvAndType$(
                env,
                ContainerType.MySQL
              ) | async
            "
          >
            <app-container-status-card
              [env]="env"
              [containerDef]="container"
            ></app-container-status-card>
          </div>
          <!-- Postgres -->
          <div
            *ngFor="
              let container of getDefinedContainersForEnvAndType$(
                env,
                ContainerType.Postgres
              ) | async
            "
          >
            <app-container-status-card
              [env]="env"
              [containerDef]="container"
            ></app-container-status-card>
          </div>
          <!-- Redis -->
          <div
            *ngFor="
              let container of getDefinedContainersForEnvAndType$(
                env,
                ContainerType.Redis
              ) | async
            "
          >
            <app-container-status-card
              [env]="env"
              [containerDef]="container"
            ></app-container-status-card>
          </div>
        </div>

        <div class="mc-containers">
          <!-- MC Containers -->
          <h3>
            Server Containers <span class="minitext">(World Groups)</span>
          </h3>
          <app-minecraft-containers-table
            [env]="env"
            [containers$]="
              getDefinedContainersForEnvAndType$(env, ContainerType.Minecraft)
            "
          ></app-minecraft-containers-table>
        </div>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>
