<div>
  <button
    mat-mini-fab
    matTooltip="Start the container"
    (click)="startContainer()"
    [disabled]="containerRunning$ | async"
  >
    <mat-icon> play_circle_filled </mat-icon>
  </button>
  <button
    mat-mini-fab
    matTooltip="Stop Container"
    (click)="stopContainer()"
    [disabled]="!(containerRunning$ | async)"
  >
    <mat-icon> stop_circle </mat-icon>
  </button>
  <button
    *ngIf="containerDef.isMinecraftContainer"
    mat-mini-fab
    matTooltip="Edit filesystem and configs"
    (click)="editContainerConfig()"
  >
    <mat-icon> edit </mat-icon>
  </button>
  <!-- Really need to make this functional/useful first -->
  <button
    *ngIf="containerDef.isMinecraftContainer"
    mat-mini-fab
    matTooltip="Connect to this world group's server console."
    (click)="openServerConsole()"
  >
    <mat-icon svgIcon="console" class="filter-to-white"></mat-icon>
  </button>
  <button
    *ngIf="containerDef.isBackupsEnabled"
    mat-mini-fab
    matTooltip="Manage Backups"
    (click)="manageBackups()"
  >
    <mat-icon> cloud_upload </mat-icon>
  </button>
  <!-- Should be a dedicated dialog popup -->
  <!-- <button
    *ngIf="containerDef.isMinecraftContainer"
    mat-mini-fab
    matTooltip="Copy configs from container to bindmounts"
    (click)="copyConfigs()"
  >
    <mat-icon> file_copy </mat-icon>
  </button> -->
</div>
