<mat-card
  appearance="outlined"
  class="container"
  [ngClass]="{
    'minecraft-containers': containerDef.isMinecraftContainer,
    'aux-containers': containerDef.isAuxContainer
  }"
>
  <mat-card-title>
    <div class="container-name">
      <div class="">{{ containerDef.getFormattedContainerName() }}</div>
      <div>
        <app-container-status-light
          [containerDef]="containerDef"
        ></app-container-status-light>
      </div>
    </div>
  </mat-card-title>
  <mat-card-content>
    <app-container-status [containerDef]="containerDef"></app-container-status>
    <app-container-actions
      [env]="env"
      [containerDef]="containerDef"
    ></app-container-actions>
  </mat-card-content>
</mat-card>
