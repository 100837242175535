<div mat-dialog-title>{{ data?.title ?? "Are you sure?" }}</div>
<mat-dialog-content class="description">
  <div *ngIf="data?.description as description" [innerHtml]="description"></div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button type="button" (click)="onCancel()">Cancel</button>
  <button mat-button color="warn" type="button" [mat-dialog-close]="true">
    OK
  </button>
</mat-dialog-actions>
