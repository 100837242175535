<div class="dialog-content">
  <mat-toolbar>
    <span>Backups for {{ containerDef.getHostname() }}</span>
    <button
      mat-icon-button
      color="accent"
      (click)="createNewBackup()"
      matTooltip="Create New Backup"
    >
      <mat-icon>
        create_new_folder
      </mat-icon>
    </button>
  </mat-toolbar>

  <div class="backups">
    <div
      class="backups_list"
      *ngIf="areBackupsReadyToRender$ | async"
    >
      <table
        mat-table
        [dataSource]="(backupsDataSource$ | async) ?? []"
        class="mat-elevation-z8 backups_table"
      >
        <!-- Position Column -->
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef>Date</th>
          <td mat-cell *matCellDef="let element">{{element.time.toLocaleString()}}</td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef>Id</th>
          <td mat-cell *matCellDef="let element">{{element.shortId}}</td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="tags">
          <th mat-header-cell *matHeaderCellDef>Tags</th>
          <td mat-cell *matCellDef="let element">{{element.tags.join(", ")}}</td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
            (click)="backupChoiceSelected(row)"
            mat-row
            *matRowDef="let row; columns: displayedColumns;"
        ></tr>
      </table>
    </div>
    <div
      *ngIf="!(areBackupsReadyToRender$ | async)"
      class="center-contents"
    >
      <mat-spinner></mat-spinner>
    </div>
    <mat-card
      appearance="outlined"
      *ngIf="(isBackupSelected$ | async)"
      class="backup-choice"
    >
      <mat-card-title class="backup-choice_title-box">
        <div class="backup-choice_title">Backup Details</div>
        <div class="backup-choice_deselect-choice" (click)="deselectBackupChoice()">
          <button mat-icon-button>
            <mat-icon>
              cancel
            </mat-icon>
          </button>
        </div>
      </mat-card-title>
      <mat-card-content>
        <div class="backup-choice_details">
          <div class="backup-choice_restic-version-title">Restic Version:</div>
          <div class="backup-choice_restic-version-value">{{(selectedBackup$ | async)?.programVersion}}</div>

          <div class="backup-choice_backup-date-title">Backup Date:</div>
          <div class="backup-choice_backup-date-value">{{(selectedBackup$ | async)?.time}}</div>

          <div class="backup-choice_snapshot-id-title">Shapshot ID:</div>
          <div class="backup-choice_snapshot-id-value">{{(selectedBackup$ | async)?.shortId}}</div>

          <div class="backup-choice_tags-title">Tags</div>
          <div class="backup-choice_tags-value">{{(selectedBackup$ | async)?.tags?.join(", ")}}</div>
        </div>
        <div
          class="backup-choice_confirm-button"
          matTooltip="{{(containerRunning$ | async) ? 'Cannot perform rollback while container is running' : 'Confirm rollback'}}">
          <button
            mat-raised-button
            color="primary"
            (click)="backupChoiceConfirmed()"
            [disabled]="(containerRunning$ | async)"
          >
            Confirm Rollback
          </button>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>