<div class="top-container">
  <mat-toolbar color="primary">
    <h1 style="font-style: italic">Yakumo Dash</h1>
  </mat-toolbar>
  <section>
    <mat-card appearance="outlined">
      <div style="margin-bottom: 8px; font-style: italic">
        Let The Machine Consume You
      </div>
      @if (bypassGoogleAuth) {
        <button mat-raised-button (click)="bypassLogin()">"Log in"</button>
      }
      @else {
        <asl-google-signin-button></asl-google-signin-button>
      }
    </mat-card>
  </section>
</div>
